@import 'components/variables';
@import '~bootstrap/scss/bootstrap';
@import 'bootstrap/dist/css/bootstrap.css';

.content-container {
  min-height: calc(100vh - 30px);
}

#logoBox {
  width: 100%;
  background: $navy;
  margin-bottom: 10px;
}

* {
  font-family: 'Quicksand';
}

.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}

#logo {
  height: 140px;
}

#loading {
  color: $navy;
}

.loading-ellipsis {
  font-size: 30px;
  width: auto;
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

#footer {
  width: 100%;
  color: $light-yellow;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: $navy;
  font-family: 'Quicksand';
  font-weight: 500;
  a:link,
  a:visited {
    text-decoration: none;
    color: $light-yellow;
  }
  a:hover,
  a:active {
    color: $dark-orange;
  }
}

.card-footer {
  a:link,
  a:visited {
    text-decoration: none;
    color: $navy;
  }
  a:hover,
  a:active {
    color: $dark-orange;
  }
}
