.button {
  margin-bottom: 15px;
}

#cards {
  padding: 3%;
}

#card-container {
  display: grid;
}

.card-deck {
  width: 100%;
}

.col{
  padding-bottom: 10px;
}

.title {
  font-weight: 700;
}

.individualCards {
  min-width: '20%';
  flex-grow: 0;
}
